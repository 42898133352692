import { nodeInstance } from "@/plugins/axios";

const locationService = {};

locationService.getCountries = () => {
  return nodeInstance.get('/api/locations/countries');
}
locationService.getStates = (country) => {
  return nodeInstance.get(`/api/locations/countries/${country}/states`);
}
locationService.getCities = (country, state) => {
  return nodeInstance.get(`/api/locations/countries/${country}/states/${state}/cities`);
}
locationService.getCommunes = () => {
  return nodeInstance.get('/api/locations/communes');
}
locationService.getNeighborhoods = (commune) => {
  return nodeInstance.get(`/api/locations/commune/${commune}/neighborhood`);
}

export default locationService;
